// @import url("https://use.typekit.net/xnq3ubj.css");

@import 'global/reset';
@import 'global/base';
@import 'global/typography';
@import 'global/variables';
@import 'global/mixins';
@import 'global/styleguide';
@import 'global/layout';
@import 'global/transitions';


// includes
@import 'includes/nav';

// Templates
@import 'layouts/index';
@import 'layouts/page';
@import 'layouts/project';
@import 'layouts/press';