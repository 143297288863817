.project-wrap  {



    section {
        border-top:$border-black;
        padding-top: $gapGlobal+10;

        padding-bottom: $gapGlobal*9;
    
        p {
            color: $grey;
        }
    
    }


    .intro {
        .title {
            grid-column: 1 / span 1;
        }

        div.intro {
            grid-column: 2 / span 1;
            max-width: 20em;
        }
    }

    .video-container {
        padding-top: $gapGlobal*9;
        grid-column: 1 / span 6;
    }
    
    .awards {

        .title {
            grid-column: 1 / span 1;
        }

        .awards-icon-wrap {
            grid-column: 2/ span 1;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: $gapGlobal;
            

            picture {
                padding-right: 33%;
                grid-column: span 1;
                filter: invert(100%);
            }
        }
    }

    .poster {
        .title {
            grid-column: 1 / span 1;
        }
     
        .poster {
            grid-column: 2 / span 1;
        }

    }

    .stills-wrap {
        background-color: $black;

        .title {
            grid-column: 1 / span 1;
            color: $white;
        }

        .still {
            grid-column: span 1;
        }
     
        .modal {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            pointer-events: 0;
            overflow: hidden;
            z-index: $z-hidden;

            .modal-inner {
                margin: 16.5vh;
            }

            .modal-close {
                color: $white;
                padding-top: $gapGlobal;
                display: block;

                position: absolute;

                right: 0;
                padding: $gapGlobal*2;
                z-index: $z-top+1;
                

            }

            transition: $tran-fadeIn;

        }
        


    }


}

html.modal-open {

    body {
        max-height: 100vh;
        overflow-y: hidden;
    }

    .stills-wrap {

        .modal {
            position: fixed;
            // top: auto
            opacity: 1; 
            z-index: $z-top;
            background-color: $black;
            transition: $tran-fadeIn;

            .modal-inner {
                z-index: $z-top + 1;
            }

            .modal-close {
                height: auto;
                z-index: $z-top + 2;
            }
        }
    }
}


@include mq($break-l) {
    .project-wrap {

        section {
            padding-bottom: $gapGlobal*4.5;
        }

        .intro, .awards {
            .title {
                grid-column: 1 / span 2;
                padding-bottom: $gapGlobal*4.5;
                word-wrap: break-word;
                -webkit-hyphens: auto;
                -ms-hyphens: auto;
                hyphens: auto;
            
            }

            div.intro {
                grid-column: 1 / span 2;
            }
        }

        .video-container {
            padding-top: $gapGlobal*4.5;
        }

        .awards {
            .awards-icon-wrap {
                grid-column: 1 / span 2;
                grid-template-columns: repeat(4, 1fr);
            }
        }

        .stills-wrap {
            .modal {
                .modal-inner {
                    margin: $gapGlobal;
                    transform: translateY(-50%);
                    top: 50%;
                }
              
            }
        }

    }
}

@include mq($break-m) { 
    .project-wrap {

        section {
            padding-bottom: $gapGlobal*2.25;
        }

        .intro, .awards,.poster, .stills-wrap {
            .title {
                grid-column: 1 / span 2;
                padding-bottom: $gapGlobal*2.25;
            }

            div.intro {
                grid-column: 1 / span 2;
            }
        }

        .video-container {
            padding-top: $gapGlobal*2.25;
        }
        
        .awards {
            .awards-icon-wrap {
                grid-column: 1 / span 2;
                grid-template-columns: repeat(3, 1fr);

                picture {
                    padding-right: 0;
                }
            }
        }

        .poster {
            .poster {
                grid-column: 1 / span 2;
            }
        }

        .stills-wrap {
            .still {
                grid-column: 1 / span 2;
            }
        }
    }
    

}