.press-wrap {

    section {
        border-top:$border-black;
        padding-top: $gapGlobal;

        padding-bottom: $gapGlobal*3;
    
    }
 

    .page-content {
        .press-feature {

            .title {
                grid-column: 1 / span 1;
                max-width: 20em;
                a {
                    color: $grey;
                }

                word-wrap: break-word;
                -webkit-hyphens: auto;
                -ms-hyphens: auto;
                hyphens: auto;
            }
            
            .copy {
                grid-column: 2 / span 1;
                max-width: 20em;
            }



        }

        // border-bottom: $border-black;
    }


}

@include mq($break-m) {
    .press-wrap {
        section {
            border-top:$border-black;
            padding-top: $gapGlobal;
    
            padding-bottom: $gapGlobal*2.25;
        }
     
    
        .page-content {
            .press-feature {
    
                .title {
                    grid-column: 1 / span 2;
                    max-width: 20em;
                    margin-bottom: $gapGlobal+5;
                    a {
                        color: $grey;
                    }
                }
                
                .copy {
                    grid-column: 1 / span 2;
                    max-width: 20em;
                }
    
    
    
            }
    
            // border-bottom: $border-black;
        }
    
    }
}