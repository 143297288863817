.wrapper {
    padding: 0 $gapGlobal*2;
    width: 100%;
}

.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: $gapGlobal;
}


header {
  
    .header-inner {

        padding-top: $gapGlobal*2;

        @include flex-space-between;
        flex-wrap: wrap;
        .logo-wrap {
            width: 50%;
            padding-right: $gapGlobal/2;
            margin-top: 2.5px;
            svg {
                height: $h1size;
                width: auto;
            }

        }

        #main-nav {
            width: 50%;
            padding-left: $gapGlobal/2;
        }
        width: 100%;
    }

    height: $headerHeight;
}

main {
    background-color: $white;
    min-height: 66vh;
    z-index: $z;
    position: relative;
    // margin-bottom: $footerHeight;
}


footer {
    // position: fixed;
    // bottom: 0;
    // left: 0;
    position: relative;
    width: 100%;

    z-index: $z;

    background-color: $yellow;
    
    height:  $footerHeight;

    p {
        margin-bottom: 0;
    }

    .footer-inner { 
        // position: relative;
        margin: 0 auto;
        padding-top: $gapGlobal;
        

        .copyright{
            grid-column: 1 / span 1;
        }

        .footer-copy {
            grid-column: 2 / span 1;
            @include flex-space-between;
            flex-wrap: wrap;

            .description {
                width: 100%;
                margin-bottom: $gapGlobal;
                padding-right: $gapGlobal + 10;
                a {
                    width: 100%;
                    display: block;
                }
            }

            // .contact {
            //     // margin-left: $gapGlobal;
            //     // text-align: right;
            //     width: 50%;
            //     a {
            //         width: 100%;
            //         display: block;
            //     }
            // }


        }

    }


    .credit {
        font-size: 12px;

        position: absolute;
        bottom: $gapGlobal*2;
        a {

            grid-column: 2;
        }
    }


}

@include mq($break-l) {
    header {
        .header-inner {
            .logo-wrap {
                svg {
                    // width: 80%;
                    height: $h1size - 8;
                }
                
            }
        }
      
    }

    footer {
        .footer-inner {
            .footer-copy {
                .description,.contact {
                    width: 100%;
                    margin-bottom: $gapGlobal;
                }
            }


            padding-bottom: $gapGlobal*2;
        }

        .credit {
            display: flex;
            position: relative;
            bottom: auto;
            padding-bottom: $gapGlobal;
        }

    }
}

@include mq($break-m) {

    .wrapper {
        padding: 0 $gapGlobal;
    }

    header {

        // height: 25vh;

        .inline-icon {
            svg {
                width: 90%;
            }
        }

        .header-inner {
            padding-top: $gapGlobal;
          

            .logo-wrap {
                width: 100%;
                padding: 0 0 2px 0;
            }

            #main-nav {
                width: 100%;
                padding: 0;
                transform: translateY(-6px)translateX(-1.5px);
            }
        }   
    }

    main {
        // margin-bottom: $gapGlobal*14;
    }

    footer {
        height: $gapGlobal*14;
        .footer-inner {
            .copyright, .footer-copy {
                grid-column: 1 / span 2;
                max-width: 20em;

               

            }
        }
    }
}