#main-nav {
    &.h1 {
        text-transform: uppercase;
    }
    
    a {
        width: 100%;
        display: inline-block;
        
        color: $grey;


   
        &:hover {
            color:$black;
        }
   
        &.active {
            color:$black;

            &:hover {
                color: $grey;
            }

        }

        span.slash {
            font-weight: 300;
            font-family: arial, sans-serif;
        }

        

    }

    line-height: .9;

}