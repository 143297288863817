body {
    font-family: neue-haas-grotesk-text,sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 33px;
    line-height: 1.2;
}

a {
    color: $black;

    &:hover {
        color: $bodyGrey;
        transition: $tran-textColor;
    }

}

p {
    margin-bottom: 1.2em
}

h1, .h1 {
   @include druk;
    font-style: normal;
    font-size: 42px;
    line-height: 1;
    text-transform: uppercase;
}

h2 .h2 {
    font-family: $neu;
    font-weight: 400;
    font-style: normal;
    color: $black;
    font-size: 33px;
    line-height: 1.2;
}

h3, .h3 {
    @include druk;
    font-style: normal;
    font-size: 17.5px;
    text-transform: uppercase;
}

h4, .h4 {
    @include druk;
    font-style: normal;
    font-size: 33px;
    color: $black;
    text-transform: uppercase;
}


@include mq($break-l) {

    body {
        font-size: 26px;
    }
 
    h1, .h1 {
        font-size: 33px;
        line-height: 1;
    }
    
    h2, .h2 {
        font-size: 26px;
    }

    h4, .h4 {
        font-size: 26px;
    }

}