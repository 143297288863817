

main {
    animation: fadeIn 900ms $fadeInBezier;
}

.observed {
    opacity: 0 !important;

    transition: $tran-fadeIn;

    &.in-view {
        opacity: 1 !important;
    }
} 