@mixin flex-space-between {
    
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;

    * {
        min-width: 0;
        min-height: 0;
    }

}

@mixin mq($width) {
    @media (max-width: $width) {
        @content;
    }
} 

@mixin druk {
    font-family: $druk;
    font-weight: 900;
}