

$white: #fff;
$black: #000;
$grey:  rgba(0, 0, 0, 0.18);
$yellow: #ECB54B;
$bodyGrey: rgba( 0,0,0,0.38);


$h1size: 33px; 

$headerHeight: 33vh;   
$footerHeight: 360px;

$neu: 'neue-haas-grotesk-display', sans-serif;
$druk: 'Druk Wide Web', sans-serif; 
$nimbus:  nimbus-sans-extended,sans-serif; 

// $letter-spacing: -0.0075em;
// $line-global: 1.2;

// $gutter-global: 20px;
// $row-global: 200px;

// $body-size: 26px;

$gapGlobal: 20px;

$border-black: 1px solid $black;

$tran-textColor: color 300ms ease-in-out;
$tran-sizeHover: height 300ms cubic-bezier(0.73,0.27,0.36,0.78);
$tran-fadeIn: opacity 640ms cubic-bezier(0.46,0.03,0.52,0.96);

$fadeInBezier: cubic-bezier(0.46,0.03,0.52,0.96);

// $text-transition: all 500ms cubic-bezier(0.45,0.05,0.55,0.95);

// $mq-xl:1440px;
// $mq-l: 1080px;
// $mq-m: 920px;
// $mq-s: 760px;
// $mq-xs: 450px;
$z-hidden: -1;
$z-bottom: 0;
$z: 1;
$z-top: 2; 


$break-l: 770px;
$break-m: 640px;
