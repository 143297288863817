#page-index {

    background-color: $black;
    a.project-link {
        color: $black;
        transition: none;

        &:hover, &:active {
            color: $black;
            transition: none;
        }
    }

    .project {
        position: relative;
        transition: background-image 900ms $fadeInBezier, opacity 640ms $fadeInBezier;
        // transition: $tran-fadeIn;

        min-height: 66vh;
        height: 66vh;

        background-color: $black;
        background-position: center;
        background-size: cover;
        // transition: background-image 320ms ease-in;
      

        .project-inner {
            height: 100%;
            padding-top: $gapGlobal+10;
            padding-bottom: $gapGlobal*4;


            .project-info {

                grid-column: 2 / span 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .project-header {
                    text-transform: uppercase;
                    color: $white;
                }

                .awards {

                    
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-gap: $gapGlobal;
                    

                    picture {
                        padding-right: 33%;
                    }
                }
            }
        }



    }


}

@include mq($break-l) {
    #page-index {
        .project {
            .project-inner {
                .project-info {
                    .awards {

                        grid-template-columns: repeat(2, 1fr);

                        picture {
                            padding-right: 0;
                        }
                    }
                }
            }
        }
    }
}

@include mq($break-m) {
    #page-index {
        .project {

            // min-height: 66vh;
            height: 100%;
            background-size: cover;

            &:hover, &:active {
                height: auto;
            }
            .project-inner {
                padding-bottom: $gapGlobal;
                padding-top: $gapGlobal;
                min-height: 66vh;
            
                .project-info {

                    grid-column: 1 / span 2;
                    .project-header {
                        margin-bottom: $gapGlobal*3;

                        word-wrap: break-word;
                        hyphens: auto;
                        -webkit-hyphens: auto;
                        -ms-hyphens: auto;

                    }
                    .awards {

                        
                        display: flex;
                        justify-content: flex-start;
                        flex-direction: row;
                        flex-wrap: wrap;
                        align-items: flex-start;

                        picture {
                            padding-right: $gapGlobal;
                            max-width: $gapGlobal*6;
                        }
                    }
                }
            }
        }
    }
}