.page-wrap {
    .page-content {
        grid-column: 2 / span 1;
        max-width: 20em;

        a {
            text-decoration: underline;
        }
    }   
    padding-bottom: $gapGlobal*4;

    &.contact {
        p, a {
            color: $bodyGrey;
        }
    }

}

@include mq($break-l) {
    .page-wrap {
        .page-content {
            grid-column: 1 / span 2;
            }
    }
    
}

@include mq($break-m) {
    .page-wrap {
        .page-content {
            grid-column: 1 / span 2;
            margin-top: $gapGlobal;
        }
    }
    
}